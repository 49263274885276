import React, {useRef, useState} from "react";
import Navbar from "../../components/Navbar";
import JobList from "../../components/JobList";
import {graphql, Link} from "gatsby";

import Marquee from "react-fast-marquee";
import Layout from "../../components/Layout";
import Head from "../../components/Head";
import MyPortableText from "../../components/MyPortableText";
import MyPortableTextMeldung from "../../components/MyPortableTextMeldung";

export const query = graphql`
    {
    allSanityMeldung{
    edges
    {
    node{
      title
        bedingungen_subheadline
        bedingungen_headline
        preis_headline
        preis_subheadline
        _rawBedingungenText
        _rawKontaktText
        kontakt_title
        kontakt_btn{
            title
            link
        }
        preis {
            title
            preis
            description
        }
        acceptance_btn
        acceptance_title
        seo{
            seo_title
        }
    }
  }
  }
  allSanitySettings {
    edges {
      node {
        instalink
        fblink
        linkedinlink
      }
    }
  }
}
`


const Meldung = ({data}) => {

    const meldung = data?.allSanityMeldung?.edges[0]?.node;
    console.log(meldung)

    const serverResponse = useRef();
    const acceptanceRef = useRef();

    const navigateToForm = () => {
            if(document.getElementById('weiter').checked) {
                window.location.href = '/meldung/formular'
            } else {
                serverResponse.current.innerText = 'Bitte akzeptieren Sie die Bedingungen'
                serverResponse.current.style.color = "#F05353";
                acceptanceRef.current.style.color = "#F05353";
            }
    }

    return (
        <Layout settings={data.allSanitySettings.edges[0].node}>
            <Head title={meldung?.seo?.seo_title}/>
            <div className="mt-doublebase">
                <Marquee gradient={false} speed={40} className={'mobilemargin'}>
                    <div className="marqueeText text-white bg-orange">
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                        <span className="marqueeText__inner uppercase">{meldung?.title}</span>
                    </div>
                </Marquee>
            </div>
            <div className={"px-[20px] py-doublebase tablet:px-[80px] w-full"}>
                <div className="">
                    <p className="bold uppercase pp-mori-bold">
                        {meldung?.bedingungen_subheadline}
                    </p>
                    <h3 className={"pp-mori text-headlinemobile tablet:text-headline max-w-[400px] leading-[1] mt-base"}>
                        {meldung?.bedingungen_headline}
                    </h3>
                    <div className="mt-halfbase tablet:columns-2 w-fit">
                        <MyPortableTextMeldung value={meldung?._rawBedingungenText}></MyPortableTextMeldung>
                    </div>
                </div>
            </div>
            <div className={"bg-black px-[20px] py-doublebase tablet:px-[80px] w-full"}>
                <div className="">
                    <p className="bold uppercase text-white pp-mori-bold">
                        {meldung?.preis_subheadline}
                    </p>
                    <h3 className={"pp-mori text-headlinemobile tablet:text-headline text-orange max-w-[400px] leading-[1] mt-base"}>
                        {meldung?.preis_headline}
                    </h3>
                </div>
            </div>
            <div className="flex flex-col tablet:flex-row">
                {meldung?.preis?.map((preis, index) => (
                    <div key={index} className={`w-full tablet:w-1/3 pt-base pb-onehalfbase ${index === 0 ? 'bg-[#1C1C1C]' : ''} ${index === 1 ? 'bg-[#313131]' : ''} ${index === 2 ? 'bg-[#4E4E4E]' : ''} flex flex-col justify-center tablet:text-center px-[20px] tablet:px-0`}>
                        <div className="flex items-end tablet:justify-center mb-halfbase">
                            <div className={"text-orange text-[2rem] mr-halfbase"}>
                                €
                            </div>
                            <h4 className="text-white rioma text-[4rem] tablet:text-[4rem] laptop:text-[6rem]">{preis.preis}</h4>

                        </div>
                        <p className="text-white font-bold pp-mori-bold px-quarterbase">{preis.title}</p>
                        <p className="text-white font-light pp-mori px-quarterbase">{preis.description}</p>
                    </div>
                ))}
            </div>
            <div className={"px-[20px] py-doublebase tablet:px-[80px] w-full relative"}>
                <div className="flex items-center">
                    <label className="container24radio bold pl-halfbase !leading-[1.1] flex-1" ref={acceptanceRef}>
                        {meldung?.acceptance_title}
                        <input name={"weiter"} id={"weiter"} type="checkbox"/>
                        <span className="checkmark24 meldung customradio h-[40px] w-[40px]"></span>
                    </label>
                </div>
                <div onClick={navigateToForm} className="mt-base flex btn !w-full tablet:!w-fit bg-transparent hover:bg-black hover:text-white border border-black landingbtn smalltext bold uppercase !px-20">
                    {meldung?.acceptance_btn}
                </div>
                <p className="small bold serverresponse mt-base absolute bottom-0" ref={serverResponse}></p>
            </div>
            <div className={"bg-orange px-[20px] py-doublebase tablet:px-[80px] w-full"}>
                <h3 className={"text-white pp-mori text-headlinemobile tablet:text-headline max-w-[400px] leading-[1]"}>
                    {meldung?.kontakt_title}
                </h3>
                <div className="mt-base tablet:mt-doublebase">
                    <MyPortableTextMeldung value={meldung?._rawKontaktText}></MyPortableTextMeldung>
                </div>
                <Link to={meldung?.kontakt_btn?.link} className="btn arrowbtn white smalltext bold filled !border-black border-2 !min-w-[224px] !w-full tablet:!w-fit mt-base relative landingbtn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" className={"absolute left-[3px] top-[3px] !w-[40px] !h-[40px]"}>
                        <g id="Group_430" data-name="Group 430" transform="translate(-1290 -1780)">
                            <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(1290 1780)" fill="none" stroke="#000" stroke-width="3">
                                <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
                                <circle cx="26.5" cy="26.5" r="25" fill="none"/>
                            </g>
                            <g id="Group_23" data-name="Group 23" transform="translate(1302.162 1819.429) rotate(-90)">
                                <g id="Group_13" data-name="Group 13">
                                    <line id="Line_1" data-name="Line 1" y2="26.138" transform="translate(11.59)" fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                    <path id="Path_36" data-name="Path 36" d="M0,0,11.59,11.59,23.181,0" transform="translate(0 17.086)" fill="none" stroke="#000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <div className="bold largesection w-full text-center tablet:pl-[20px]">{meldung?.kontakt_btn?.title}</div>
                </Link>
            </div>


        </Layout>
    )
}

export default Meldung;
